import React, { useState } from 'react'
import { Link } from "gatsby"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTwitter, faLinkedin, faGithub, faBehance } from '@fortawesome/free-brands-svg-icons'
import { Twirl as Hamburger } from 'hamburger-react'
import scrollTo from 'gatsby-plugin-smoothscroll';

import headerStyles from '../header/header.module.scss'

const Header = () => {
  const [isOpen, setOpen] = useState(false)

  return (
    <header className={headerStyles.header}>
      <div className={headerStyles.header__container}>
      <div className={headerStyles.header__hamburger}>
      <Hamburger onClick={() => setOpen(isOpen => !isOpen)} toggled={isOpen} toggle={setOpen} />  
      </div>
      <h1 className={headerStyles.header__logo}><Link className={headerStyles.header__logoLink} to="/">Mo.</Link></h1>
      <div>
      <div>
        {isOpen && <nav className={headerStyles.header__nav}>
        <ul className={headerStyles.header__navList}>
        <li onClick={() => scrollTo('#about')} className={headerStyles.header__navItem}><Link className={headerStyles.header__itemLink}>About</Link></li>
        <li onClick={() => scrollTo('#projects')} className={headerStyles.header__navItem}><Link className={headerStyles.header__itemLink}>Projects</Link></li>
        <li className={headerStyles.header__navItem}><Link className={headerStyles.header__itemLink} to="/blog">Blog</Link></li>
        <li onClick={() => scrollTo('#contact')} className={headerStyles.header__navItem}><Link className={headerStyles.header__itemLink}>Contact</Link></li>

        <div className={headerStyles.header__socialsContainer}>
        <h3>Follow me</h3>
        <div className={headerStyles.header__socialsIconContainer}>
          <Link className={headerStyles.header__socialsIcon} to="https://twitter.com/mo_rob_"><FontAwesomeIcon icon={faTwitter} /></Link>
          <Link className={headerStyles.header__socialsIcon} to="https://www.linkedin.com/in/mohammed-rob-12717214b/"><FontAwesomeIcon icon={faLinkedin} /></Link>
          <Link className={headerStyles.header__socialsIcon} to="https://github.com/mo-rob-1"><FontAwesomeIcon icon={faGithub} /></Link>
          <Link className={headerStyles.header__socialsIcon} to="https://www.behance.net/mo-rob"><FontAwesomeIcon icon={faBehance} /></Link>
        </div>
        <h6 className={headerStyles.header__socialsLogo}>© Mo. | 2021</h6>
        </div>

        </ul>
      </nav>}
      </div>
      </div>
      </div>  
  </header>
  )
}

export default Header
