import React from 'react'
import { Link } from "gatsby"
import scrollTo from 'gatsby-plugin-smoothscroll';

import navStyles from '../nav/nav.module.scss'

const nav = () => {
    return (
        <nav className={navStyles.nav__Desktop}>
            <ul className={navStyles.nav__ListDesktop}>
            <li onClick={() => scrollTo('#about')} className={navStyles.nav__ItemDesktop}><Link className={navStyles.nav__itemLinkDesktop}>About</Link></li>
            <li onClick={() => scrollTo('#projects')} className={navStyles.nav__ItemDesktop}><Link className={navStyles.nav__itemLinkDesktop}>Projects</Link></li>
            <li className={navStyles.nav__ItemDesktop}><Link className={navStyles.nav__itemLinkDesktop} to="/blog">Blog</Link></li>
            <li onClick={() => scrollTo('#contact')} className={navStyles.nav__ItemDesktop}><Link className={navStyles.nav__itemLinkDesktop}>Contact</Link></li>
            </ul>
        </nav>
    )
}

export default nav
