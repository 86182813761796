import React from 'react'

import Header from '../header/header'
import Footer from '../footer/footer'
import Nav from '../nav/nav'

import '../../styles/index.scss'
import layoutStyles from './layout.module.scss'

const Layout = (props) => {
    return (
        <div className={layoutStyles.container}>
            <div className={layoutStyles.headerContainer}>
            <Header />
            <Nav />
            </div>
            {props.children}
            <Footer />
        </div>
    )
}

export default Layout