import React from 'react'
import { Helmet } from "react-helmet"

import Layout from '../components/layout/layout'
import Head from '../components/head'
import fourohfourStyles from '../styles/404.module.scss'

const NotFoundPage = () => {
  return (
    <Layout>
      <Helmet>
        <meta name="description" content="Mo is a front-end developer from Manchester, England who has an eye for design and user experience. 
        My goal is to deliver quality creative solutions across digital platforms and beyond."/>
      </Helmet>
      <Head title="404"/>
      <div className={fourohfourStyles.container}>
      <h1 className={fourohfourStyles.title}>404 - <span className={fourohfourStyles.redText}>Page Not Found!</span></h1>
      </div>
    </Layout>
  )
}

export default NotFoundPage